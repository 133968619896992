import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_avatar = _resolveComponent("v-avatar")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createBlock(_component_v_row, {
    align: "center",
    justify: "center",
    dense: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        md: "10",
        sm: "10",
        class: "mr-3 px-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            class: "mx-auto rounded-lg elevation-4 modern-card",
            title: "Contas a Receber Atrasado 30 Dias"
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_v_avatar, { class: "bg-blue-lighten-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    icon: "mdi-clock-alert",
                    class: "text-blue-darken-2"
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, { class: "text-body-1 font-weight-bold d-flex justify-center" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Valor total de contas a receber atrasadas: ")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_text, { class: "d-flex justify-center text-h5 font-weight-bold text-indigo-darken-4" }, {
                default: _withCtx(() => [
                  _createTextVNode(" R$ " + _toDisplayString($data.valor_total.toFixed(2)), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}