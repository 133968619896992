<template>
    <v-row class="d-flex justify-center align-center">
        <v-col cols="12" style="width: 100%;" class="d-flex flex-column px-0 justify-center align-center">
            <v-card class="my-6 align-center ma-10 rounded-lg elevation-2"
                style="width: 100%; background-color: transparent !important;">
                <v-card-title v-if="!display.xs" class="text-center d-flex justify-center mb-3" style="
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    color: #4CAF50;
                    letter-spacing: 2px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                    background-color: transparent !important;
                    ">
                    RESULTADO OPERACIONAL
                </v-card-title>

                <v-card-title v-else class="text-center d-flex justify-center mb-3" style="
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    color: var(--text-light);
                    letter-spacing: 2px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                    background-color: transparent !important;
                    ">
                    RESULTADO <br> OPERACIONAL
                </v-card-title>

                <v-row class="d-flex">
                    <v-col class="mb-6 ml-3" cols="11" md="6" lg="4">
                        <ejs-daterangepicker :placeholder="waterMarkText" @change="onDateRangeChange">
                            <e-presets>
                                <e-preset label="Mês Atual" :start='currentMonthStartDate'
                                    :end='currentMonthEndDate'></e-preset>
                                <e-preset label="Mês Anterior" :start='oneMonthStartDate'
                                    :end='oneMonthEndDate'></e-preset>
                                <e-preset label="3 Meses Anteriores" :start='threeMonthsStartDate'
                                    :end='threeMonthsEndDate'></e-preset>
                                <e-preset label="6 Meses Anteriores" :start='sixMonthsStartDate'
                                    :end='sixMonthsEndDate'></e-preset>
                                <e-preset label="Último Ano" :start='twelveMonthsStartDate'
                                    :end='twelveMonthsEndDate'></e-preset>
                            </e-presets>
                        </ejs-daterangepicker>
                    </v-col>

                    <v-col class="mb-6 ml-3" cols="11" md="6" lg="4">
                        <table id="property" title="Properties" style="width: 100%">
                            <tbody>
                                <tr style="height: 50px">
                                    <td>
                                        <div>
                                            <ejs-dropdownlist id="charttypesddl" :change="ddlOnChange"
                                                :dataSource="chartTypes" index="0"
                                                floatLabelType="Auto"></ejs-dropdownlist>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-col>
                </v-row>


                <ejs-pivotview v-if="hasData" id="pivotview" ref="pivotview" :dataSourceSettings="dataSourceSettings"
                    :gridSettings="gridSettings" :height="height" :allowExcelExport="allowExcelExport"
                    :allowConditionalFormatting="allowConditionalFormatting" :allowPdfExport="allowPdfExport"
                    :showToolbar="showToolbar" :allowNumberFormatting="allowNumberFormatting"
                    :allowCalculatedField="allowCalculatedField" :showFieldList="showFieldList" :toolbar="toolbar"
                    :saveReport="saveReport" :loadReport="loadReport" :fetchReport="fetchReport"
                    :renameReport="renameReport" :removeReport="removeReport" :newReport="newReport"
                    :displayOption="displayOption"> </ejs-pivotview>



                <v-row v-else class="d-flex">
                    <v-col class="justify-center align-center" cols="12">
                        <v-card class="mx-2 mt-5 d-flex" height="20vh"
                            style="width: 100%; background-color: transparent !important;">
                            <v-card-text class="d-flex justify-center align-center" style="
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    color: var(--text-light);
                    letter-spacing: 2px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                    ">Nenhum dado encontrado para o período selecionado.</v-card-text>
                        </v-card>
                    </v-col>
                </v-row>


            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {
    PivotViewComponent,
    FieldList,
    CalculatedField,
    Toolbar,
    PDFExport,
    ExcelExport,
    ConditionalFormatting,
    NumberFormatting
} from "@syncfusion/ej2-vue-pivotview";
import { useDisplay } from "vuetify";
export default {
    name: "App",
    components: {
        "ejs-pivotview": PivotViewComponent
    },
    data() {
        return {
            display: useDisplay(),
            dataSourceSettings: {
                dataSource: [],
                expandAll: false,
                columns: [{}],
                values: [{}],
                rows: [{}],
                formatSettings: [{}],
                filters: []
            },
            height: 350,
            gridSettings: { columnWidth: 140 },
            allowExcelExport: true,
            allowConditionalFormatting: true,
            allowNumberFormatting: true,
            hasData: true,
            allowPdfExport: true,
            displayOption: { view: 'Both' },
            showToolbar: true,
            allowCalculatedField: true,
            showFieldList: true,
            chartTypes: ['Column', 'Bar', 'Line', 'Area'],
            toolbar: [
                "New",
                "Save",
                "SaveAs",
                "Rename",
                "Remove",
                "Load",
                "Grid",
                "Chart",
                "Export",
                "SubTotal",
                "GrandTotal",
                "ConditionalFormatting",
                "NumberFormatting",
                "FieldList"
            ]
        };
    },
    created() {
        this.initializeTheme();
        this.$watch('$vuetify.theme.dark', this.updateTheme);
    },
    watch: {
        'dataSourceSettings.dataSource': function (newVal) {
            if (this.$refs.pivotview) {
                this.$refs.pivotview.refresh();
            }
        }
    },
    mounted() {
        this.fetchInicial()
    },
    methods: {
        initializeTheme() {
            const material3Link = document.createElement('link');
            material3Link.href = 'https://cdn.syncfusion.com/ej2/24.1.41/material3.css';
            material3Link.rel = 'stylesheet';
            material3Link.id = 'material3';
            document.head.appendChild(material3Link);

            const material3DarkLink = document.createElement('link');
            material3DarkLink.href = 'https://cdn.syncfusion.com/ej2/24.1.41/material3-dark.css';
            material3DarkLink.rel = 'stylesheet';
            material3DarkLink.id = 'material3-dark';
            document.head.appendChild(material3DarkLink);

            material3Link.onload = material3DarkLink.onload = () => {
                this.setTheme(this.$vuetify.theme.dark ? 'material3-dark' : 'material3');
            };
        },
        setTheme(selectedTheme) {
            const themes = {
                "material3": "https://cdn.syncfusion.com/ej2/24.1.41/material3.css",
                "material3-dark": "https://cdn.syncfusion.com/ej2/24.1.41/material3-dark.css"
            };

            document.getElementById('material3').disabled = selectedTheme !== 'material3';
            document.getElementById('material3-dark').disabled = selectedTheme !== 'material3-dark';
        },
        updateTheme(isDark) {
            this.setTheme(isDark ? 'material3-dark' : 'material3');
        },
        saveReport: function (args) {
            let reports = [];
            let isSaved = false;
            if (
                localStorage.pivotviewReports &&
                localStorage.pivotviewReports !== ""
            ) {
                reports = JSON.parse(localStorage.pivotviewReports);
            }
            if (args.report && args.reportName && args.reportName !== "") {
                reports.map(function (item) {
                    if (args.reportName === item.reportName) {
                        item.report = args.report;
                        isSaved = true;
                    }
                });
                if (!isSaved) {
                    reports.push(args);
                }
                localStorage.pivotviewReports = JSON.stringify(reports);
            }
        },
        fetchReport: function (args) {
            let reportCollection = [];
            let reeportList = [];
            if (
                localStorage.pivotviewReports &&
                localStorage.pivotviewReports !== ""
            ) {
                reportCollection = JSON.parse(localStorage.pivotviewReports);
            }
            reportCollection.map(function (item) {
                reeportList.push(item.reportName);
            });
            args.reportName = reeportList;
        },
        loadReport: function (args) {
            let pivotObj = document.getElementById('pivotview').ej2_instances[0];
            let reportCollection = [];
            if (
                localStorage.pivotviewReports &&
                localStorage.pivotviewReports !== ""
            ) {
                reportCollection = JSON.parse(localStorage.pivotviewReports);
            }
            reportCollection.map(function (item) {
                if (args.reportName === item.reportName) {
                    args.report = item.report;
                }
            });
            if (args.report) {
                pivotObj.dataSourceSettings = JSON.parse(args.report).dataSourceSettings;
            }
        },
        removeReport: function (args) {
            let reportCollection = [];
            if (
                localStorage.pivotviewReports &&
                localStorage.pivotviewReports !== ""
            ) {
                reportCollection = JSON.parse(localStorage.pivotviewReports);
            }
            for (let i = 0; i < reportCollection.length; i++) {
                if (reportCollection[i].reportName === args.reportName) {
                    reportCollection.splice(i, 1);
                }
            }
            if (
                localStorage.pivotviewReports &&
                localStorage.pivotviewReports !== ""
            ) {
                localStorage.pivotviewReports = JSON.stringify(reportCollection);
            }
        },
        renameReport: function (args) {
            let reportCollection = [];
            if (
                localStorage.pivotviewReports &&
                localStorage.pivotviewReports !== ""
            ) {
                reportCollection = JSON.parse(localStorage.pivotviewReports);
            }
            reportCollection.map(function (item) {
                if (args.reportName === item.reportName) {
                    item.reportName = args.rename;
                }
            });
            if (
                localStorage.pivotviewReports &&
                localStorage.pivotviewReports !== ""
            ) {
                localStorage.pivotviewReports = JSON.stringify(reportCollection);
            }
        },
        newReport: function () {
            let pivotGridObj = document.getElementById('pivotview').ej2_instances[0];
            pivotGridObj.setProperties(
                {
                    dataSourceSettings: {
                        columns: [],
                        rows: [],
                        values: [],
                        filters: []
                    }
                },
                false
            );
        },
        async fetchInicial() {
            const { message } = await frappe.call({
                method: "nxlite.nx_financeiro.page.meu_financeiro.controller.chart_pivot_financeiro"
            });
            console.log(await message);

            this.dataSourceSettings = {
                dataSource: message,
                expandAll: true,
                columns: [
                    { name: 'entidade_name', caption: 'Entidade' },
                    { name: 'titulo_name', caption: 'Titulo' }
                ],
                values: [
                    { name: 'titulo_valor', caption: 'Valor do Titulo' },
                    { name: 'entidade_capital_social', caption: 'Capital Social' }
                ],
                rows: [
                    { name: 'entidade_razao_social', caption: 'Razão Social' },
                    { name: 'entidade_cnpj', caption: 'CNPJ' }
                ],
                formatSettings: [
                    { name: 'titulo_valor', format: 'C0' },
                    { name: 'entidade_capital_social', format: 'C0' }
                ],
                filters: []
            };

            this.hasData = dataSource.length > 0;


            if (this.$refs.pivotview) {
                this.$refs.pivotview.refresh();
            }
        }
    },
    provide: {
        pivotview: [FieldList, CalculatedField, Toolbar, PDFExport, ExcelExport, ConditionalFormatting, NumberFormatting]
    }
}
</script>

<style>
@import 'https://cdn.syncfusion.com/ej2/24.1.41/material3.css';

.e-pivot-button .e-pvt-btn-content {
    color: var(--text-light) !important;
}
#pivotview_chart_ChartBorder text {
    fill: var(--text-light) !important; 
}
#pivotview_chart_ChartBorder text,
#pivotview_chart_ChartBorder tspan {
    fill: var(--text-light) !important;
}

#pivotview_chart_AxisTitle_0 {
    fill: var(--text-light) !important;
}

#pivotview_chart_AxisTitle_2,
#pivotview_chart_AxisTitle_2 tspan {
    fill: var(--text-light) !important;
}

.e-stackedheadertext.e-cellvalue {
    color: var(--text-light) !important;
}


</style>