<template>

    <v-row align="center" justify="center" dense>
        <v-col cols="12" md="10" sm="10" class="mr-3 px-3">
            <v-card class="mx-auto rounded-lg elevation-4 modern-card" title="Contas a Receber Atrasado 30 Dias">
                <template v-slot:prepend>
                    <v-avatar class="bg-blue-lighten-5">
                        <v-icon icon="mdi-clock-alert" class="text-blue-darken-2"></v-icon>
                    </v-avatar>
                </template>

                <v-card-text class="text-body-1 font-weight-bold d-flex justify-center">
                    Valor total de contas a receber atrasadas:
                </v-card-text>

                <v-card-text class="d-flex justify-center text-h5 font-weight-bold text-indigo-darken-4">
                     R$ {{valor_total.toFixed(2)}}
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>


</template>

<script>
export default {
    name: 'cardContasAReceberAtrasados',
    data() {
        return {
            valor_total: 0
        }
    },
    mounted() {
        this.fetchInicial()
        
        frappe.realtime.on("Titulo", async (data) => {
            console.log(data)
            setTimeout(async () => {
                await this.fetchInicial()
            }, 1000);
        })
    },
    methods: {
        async fetchInicial(){
            const {message} = await frappe.call(
                "nxlite.nx_financeiro.page.meu_financeiro.controller.card_contas_a_receber"
            )
            this.valor_total = message.Valor_total 
        }
    }
}
</script>