<template>
    <div>
        <v-card class="d-flex justify-center">
            <v-card-title>
                teste
            </v-card-title>
        </v-card>
        <div class="card">
            <div class="card-title">Análise dos Impostos Sobre Faturamento</div>
            <div class="card-text">
                <div id="controls">
                    <label for="period_filter">Selecione o Período:</label>
                    <select v-model="selectedPeriod" @change="handlePeriodChange" id="period_filter">
                        <option value="1_month">Último Mês</option>
                        <option value="3_months">Últimos 3 Meses</option>
                        <option value="6_months">Últimos 6 Meses</option>
                        <option value="1_year">Último Ano</option>
                        <option value="custom">Período Customizado</option>
                    </select>
                    <div v-if="showCustomPeriod" id="custom_period_controls">
                        <label for="start_date">Data Início:</label>
                        <input type="date" v-model="startDate" id="start_date">
                        <label for="end_date">Data Fim:</label>
                        <input type="date" v-model="endDate" id="end_date">
                    </div>
                    <button @click="applyFilter" id="filter_button">Filtrar</button>
                </div>
                <ejs-chart id="container" :title="chartTitle" :primaryXAxis="primaryXAxis"
                    :primaryYAxis="primaryYAxis" :chartArea="chartArea" :tooltip="tooltip" :legendSettings="legend"
                    width="1500px" 
                    height="400px"
                    >
                    <e-series-collection>
                        <e-series v-for="(series, index) in seriesData" :key="index" :dataSource="chartData"
                            :type="series.type" :xName="series.xName" :yName="series.yName" :name="series.name"
                            :width="series.width" :columnWidth="series.columnWidth" :border="series.border"></e-series>
                    </e-series-collection>
                </ejs-chart>
            </div>
        </div>
    </div>

  </template>
  <!-- teste -->
  <script>
  
  import {
    ChartComponent,
    SeriesCollectionDirective,
    SeriesDirective,
    StackingColumnSeries,
    Legend,
    Category,
    Tooltip,
    Highlight,
} from "@syncfusion/ej2-vue-charts";

export default {
    name: "stacked_column",
    components: {
        "ejs-chart": ChartComponent,
        "e-series-collection": SeriesCollectionDirective,
        "e-series": SeriesDirective,
    },
    data() {
        return {
            selectedPeriod: "1_month",
            startDate: new Date().toISOString().split("T")[0],
            endDate: new Date().toISOString().split("T")[0],
            showCustomPeriod: false,
            chartTitle: "Meu Faturamento por Mês",
            primaryXAxis: {
                valueType: "Category",
                labelIntersectAction: "Rotate45",
            },
            primaryYAxis: {
                title: "Faturamento",
                labelFormat: "{value}",
            },
            chartArea: {
                border: { width: 0 },
            },
            tooltip: {
                enable: true,
            },
            legend: { visible: true, position: "Bottom" },
            chartData: [],
            seriesData: [
                {
                    type: "StackingColumn",
                    xName: "x",
                    yName: "zff",
                    name: "Valor Líquido NFE",
                    width: 2,
                    columnWidth: 0.6,
                    border: { color: "#ffffff", width: 1 },
                },
                {
                    type: "StackingColumn",
                    xName: "x",
                    yName: "nfe_impostos",
                    name: "ainnn",
                    width: 2,
                    columnWidth: 0.6,
                    border: { color: "#ffffff", width: 1 },
                },
                {
                    type: "StackingColumn",
                    xName: "x",
                    yName: "nfse_valor_liquido",
                    name: "Valor Líquido NFSE",
                    width: 2,
                    columnWidth: 0.6,
                    border: { color: "#ffffff", width: 1 },
                },
                {
                    type: "Line",
                    xName: "x",
                    yName: "nfe_valor_bruto",
                    name: "Faturamento Bruto NFE",
                    width: 2,
                    border: { color: "#8BC34A", width: 1 },
                },
            ],
        };
    },
    methods: {
        async puxar_dados(){
           
            const response = await frappe.call({
                method: "nxlite.nx_faturamento.page.meu_faturamento.controller.Stacked_Column",
            });
            console.log('puxando dados',response.message)
        },
        handlePeriodChange() {
            this.showCustomPeriod = this.selectedPeriod === "custom";
        },
        async applyFilter() {
            if (this.selectedPeriod === "custom" && (!this.startDate || !this.endDate)) {
                this.$toast.error("Por favor, selecione ambas as datas de início e fim.");
                return;
            }
            const dates = this.calculateDates();
            this.startDate = dates.startDate;
            this.endDate = dates.endDate;
            this.fetchChartData();
        },
        // calculateDates() {
        // },
        // formatChartData(data) {
        //     return Object.keys(data).map((monthYear) => {
        //         const nfe = data[monthYear]?.NFE || {};
        //         const nfse = data[monthYear]?.NFSE || {};
        //         return {
        //             x: monthYear,
        //             nfe_valor_liquido: nfe.valor_liquido || 0,
        //             nfe_impostos: Object.values(nfe.impostos || {}).reduce((a, b) => a + b, 0),
        //             nfse_valor_liquido: nfse.valor_liquido || 0,
        //             nfe_valor_bruto: nfe.valor_bruto || 0,
        //         };
        //     });
        // },
        // async fetchChartData() {
        //     try {
        //         const response = await frappe.call({
        //             method: "nxlite.nx_faturamento.page.meu_faturamento.controller.fetchFaturamento",
        //             // args: { start_date: this.startDate, end_date: this.endDate },
        //             args: { start_date: this.startDate, end_date: this.endDate },
        //         });
        //         console.log('uquiéquivem', response.message)
        //         this.chartData = this.formatChartData(response.message || []);
        //     } catch (error) {
        //         console.error("Erro ao buscar os dados do gráfico:", error);
        //     }
        // },
    },
    provide: {
        chart: [StackingColumnSeries, Legend, Category, Tooltip, Highlight],
    },
    mounted(){
        // this.fetchChartData();
        this.puxar_dados();
    }
};
</script>

<style scoped>
#controls {
    margin-bottom: 20px;
}
</style>