<template>
    <v-row class="d-flex justify-center align-center">
        <v-col cols="12" style="width: 100%;" class="d-flex flex-column px-0 justify-center align-center">
            <v-card class="my-6 align-center ma-10 rounded-lg elevation-2"
                style="width: 100%; background-color: transparent !important;">
                <v-card-title v-if="!display.xs" class="text-center d-flex justify-center mb-3" style="
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    color: #4CAF50;
                    letter-spacing: 2px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                    background-color: transparent !important;
                    ">
                     FLUXO DE CAIXA PARA 12 MESES
                </v-card-title>

                <v-card-title v-else class="text-center d-flex justify-center mb-3" style="
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    color: #4CAF50;
                    letter-spacing: 2px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                    background-color: transparent !important;
                    ">
                     FLUXO DE CAIXA <br> PARA 12 MESES
                </v-card-title>

                <ejs-chart v-if="hasData" style='display:block' :theme='theme' align='center' id='fluxoDeCaixa'
                    :title='title' :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis' :chartArea='chartArea'
                    :width='width' :tooltip='tooltip' :load='load' :legendSettings='legend'>
                    <e-series-collection>
                        <e-series :dataSource='seriesData' type='Column' xName='mes' yName='valores_pagar'
                            name='Valores a pagar' width=2 :marker='marker' tooltipMappingName='MappingName'
                            columnSpacing='0.1'> </e-series>
                        <e-series :dataSource='seriesData' type='Column' xName='mes' yName='valores_receber'
                            name='Valores a receber' width=2 :marker='marker' tooltipMappingName='MappingName'
                            columnSpacing='0.1'>
                        </e-series>
                        <e-series :dataSource='seriesData' type='Column' xName='mes' yName='diferenca_mes'
                            name='Diferença do mês' width=2 :marker='marker' tooltipMappingName='MappingName'
                            columnSpacing='0.1'>
                        </e-series>
                        <e-series :dataSource='seriesData' type='Spline' xName='mes' yName='saldo_acumulado' name='Valor Acumulado'
                            width=3 :animation='animation1'> </e-series>
                    </e-series-collection>
                </ejs-chart>

                <v-row v-else class="d-flex">
                    <v-col class="justify-center align-center" cols="12">
                        <v-card class="mx-2 mt-5 d-flex" height="20vh"
                            style="width: 100%; background-color: transparent !important;">
                            <v-card-text class="d-flex justify-center align-center" style="
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    color: orange;
                    letter-spacing: 2px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                    ">Nenhum dado encontrado para o período selecionado.</v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { DateRangePickerComponent, PresetDirective, PresetsDirective } from '@syncfusion/ej2-vue-calendars';
import { Browser } from '@syncfusion/ej2-base';
import { useDisplay } from 'vuetify'
import { ChartComponent, SplineSeries, SeriesDirective, SeriesCollectionDirective, ColumnSeries, Category, DataLabel, Tooltip, Legend, Highlight } from "@syncfusion/ej2-vue-charts";
export default {
    components: {
        'ejs-daterangepicker': DateRangePickerComponent,
        'e-presets': PresetsDirective,
        'e-preset': PresetDirective, 'ejs-chart': ChartComponent,
        'e-series-collection': SeriesCollectionDirective,
        'e-series': SeriesDirective
    },
    name: "chartFluxoDeCaixa",
    data() {
        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        return {
            valorTotal: 0,
            display: useDisplay(),
            hasData: true,
            waterMarkText: 'Selecione a data',
            currentMonthStartDate: new Date(year, month, 1),
            currentMonthEndDate: new Date(year, month + 1, 0),
            oneMonthStartDate: new Date(year, month - 1, 1),
            oneMonthEndDate: new Date(year, month, 0),
            threeMonthsStartDate: new Date(year, month - 3, 1),
            threeMonthsEndDate: new Date(year, month, 0),
            sixMonthsStartDate: new Date(year, month - 6, 1),
            sixMonthsEndDate: new Date(year, month, 0),
            twelveMonthsStartDate: new Date(year - 1, month, 1),
            twelveMonthsEndDate: new Date(year, month, 0),
            seriesData: [],
            primaryXAxis: {
                valueType: 'Category',
                interval: 1,
                color: 'var(--text-light)',
                majorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
                labelIntersectAction: Browser.isDevice ? 'None' : 'Rotate45',
                labelRotation: Browser.isDevice ? -45 : 0,
                labelStyle: { color: 'var(--text-light)' }
            },
            chartArea: { border: { width: 0 } },

            animation1: { enable: true, duration: 1500 },
            animation2: { enable: true, delay: 2300, duration: 1500 },
            animation3: { enable: true, delay: 3400, duration: 1500 },
            animation4: { enable: true, delay: 4800, duration: 1500 },
            animation5: { enable: true, delay: 6200, duration: 1500 },

            primaryYAxis:
            {
                title: 'Valor (R$)',
                majorTickLines: { width: 0 }, lineStyle: { width: 0 },
                labelFormat: 'R$ {value}',
                labelStyle: { color: 'var(--text-light)' },
                titleStyle: { color: 'var(--text-light)' }
            },

            width: Browser.isDevice ? '100%' : '75%',
            marker:
                { dataLabel: { visible: false, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } },
            legend: { enableHighlight: true, textStyle: { color: 'var(--text-light)' } },
            tooltip: {
                enable: true,
                shared: true,
            },
        };
    },
    provide: {
        chart: [ColumnSeries, Legend, DataLabel, Category, Tooltip, Highlight, SplineSeries]
    },
    mounted() {
        this.fetchInicial()

        frappe.realtime.on("Titulo", async (data) => {
            console.log(data)
            setTimeout(async () => {
                await this.fetchInicial()
            }, 1000);
        })
    },
    methods: {
        async onDateRangeChange(e) {
            const startDate = e.startDate;
            const endDate = e.endDate;

            const start = startDate || this.oneMonthAgoStartDate;
            const end = endDate || this.oneMonthAgoEndDate;
            await this.fetchInicial(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'));
        },
        async fetchInicial(startDate, endDate) {
            const { message } = await frappe.call(
                "nxlite.nx_financeiro.page.meu_financeiro.controller.chart_fluxo_de_caixa",
                {
                    data_inicial: startDate,
                    data_final: endDate
                }
            )
            this.seriesData = message.dados
            this.hasData = this.seriesData.length > 0 ? true : false
        }
    }
}
</script>