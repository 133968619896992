<template>
  <v-app>
    <v-main>
      <!-- cards  -->
      <v-row>
        <cardContasAReceberAtrasados />
        <cardContasAPagarAtrasados />
        <cardContasAPagarHoje />
        <cardContasAReceberrHoje />
      </v-row>

      <chartAnaliseResultadoOperacional />
      <analiseGraficoClientesMaisRepresentativos />
      <analiseGraficoFornecedoresMaisRepresentativos />
      <chartAnaliseGraficoMaioresGastosPorPlanoDeContas />
      <chartFluxoDeCaixa />
      <chartPagoXRecebido />
      <pivotFinanceiro />
    </v-main>
  </v-app>
</template>

<script>
import cardContasAReceberAtrasados from "./app/components/cards/contas_a_receber_atrasados.vue";
import cardContasAPagarAtrasados from "./app/components/cards/contas_a_pagar_atrasados.vue";
import cardContasAPagarHoje from "./app/components/cards/contas_a_pagar_hoje.vue";
import cardContasAReceberrHoje from "./app/components/cards/contas_a_receber_hoje.vue";
import chartAnaliseResultadoOperacional from "./app/components/charts/analise_resultado_operacional.vue";
import analiseGraficoFornecedoresMaisRepresentativos from "./app/components/charts/analise_graficos_fornecedores_mais_representativos.vue";
import analiseGraficoClientesMaisRepresentativos from "./app/components/charts/analise_grafico_clientes_mais_representativos.vue";
import chartAnaliseGraficoMaioresGastosPorPlanoDeContas from "./app/components/charts/analise_grafico_maiores_gastos_por_plano_de_contas.vue";
import chartFluxoDeCaixa from "./app/components/charts/analise_grafico_fluxo_de_caixa_para_12_meses.vue";
import chartPagoXRecebido from "./app/components/charts/analise_grafico_pago_x_recebido.vue";
import pivotFinanceiro from "./app/components/charts/analise_pivot_financeiro.vue"
import { useTheme } from "vuetify";
import { onMounted } from "vue";
export default {
  components: {
    cardContasAReceberAtrasados,
    cardContasAPagarAtrasados,
    cardContasAPagarHoje,
    cardContasAReceberrHoje,

    chartAnaliseResultadoOperacional,
    analiseGraficoClientesMaisRepresentativos,
    analiseGraficoFornecedoresMaisRepresentativos,
    chartAnaliseGraficoMaioresGastosPorPlanoDeContas,
    chartFluxoDeCaixa,
    chartPagoXRecebido,
    pivotFinanceiro
  },
  name: "meuFinanceiroApp",
  data() {
    return {
    };
  },
  setup() {
    const theme = useTheme();

    const updateVuetifyTheme = () => {
      const htmlTheme = document.documentElement.getAttribute("data-theme");
      theme.global.name.value = htmlTheme === "dark" ? "dark" : "light";
      console.log("Tema do Vuetify atualizado para:", theme.global.name.value);
      updateChartLegendColor(htmlTheme);
      updateChartLegendTextColor(htmlTheme);
    };

    const updateChartLegendColor = (theme) => {
      const legendTexts = document.querySelectorAll('.e-chart-legend-text');
      legendTexts.forEach(legendText => {
        legendText.style.color = theme === 'dark' ? 'white' : 'black';
      });
    };

    const updateChartLegendTextColor = (theme) => {
      const legendText = document.querySelector('#chartcontainer_chart_legend_text_0');
      if (legendText) {
        legendText.setAttribute('fill', theme === 'dark' ? 'white' : '#666666');
      }
    };

    onMounted(() => {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "attributes" && mutation.attributeName === "data-theme") {
            console.log(`Tema alterado para: ${document.documentElement.getAttribute("data-theme")}`);
            updateVuetifyTheme();
          }
        }
      });

      observer.observe(document.documentElement, { attributes: true });

      updateVuetifyTheme();
    });

    const toggleTheme = () => {
      const newTheme = theme.global.name.value === "dark" ? "light" : "dark";
      document.documentElement.setAttribute("data-theme", newTheme);
    };

    return { toggleTheme };
  },
};
</script>

<style>
[data-theme="dark"] .e-input-group input.e-input,
[data-theme="dark"] .e-input-group input.e-input::placeholder,
[data-theme="dark"] .e-input-group .e-input-group-icon,
[data-theme="dark"] .e-input {
  color: white !important;
}

[data-theme="dark"] .e-input-group,
[data-theme="dark"] .e-input-group.e-control-wrapper {
  background-color: #2C2C2C;
  border-color: #2C2C2C;
}

body[data-theme="dark"] .chart-legend {
  color: var(--text-light) !important;
}

body[data-theme="dark"] .e-chart-legend-text {
  color: white !important;
}
</style>