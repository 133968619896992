import { registerLicense } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-treegrid/styles/material.css";
import { setCulture, L10n, loadCldr } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-base/styles/material.css";

import * as n1 from "@syncfusion/ej2-cldr-data/main/pt/currencies.json";
import * as n2 from "@syncfusion/ej2-cldr-data/main/pt/timeZoneNames.json";
import * as n3 from "@syncfusion/ej2-cldr-data/main/pt/numbers.json";
import * as n4 from "@syncfusion/ej2-cldr-data/main/pt/ca-gregorian.json";
import * as s from "@syncfusion/ej2-cldr-data/supplemental/currencyData.json";
import * as s2 from "@syncfusion/ej2-cldr-data/supplemental/numberingSystems.json";

import "@syncfusion/ej2-treegrid/styles/material.css";
import "@syncfusion/ej2-treegrid/styles/material-dark.css";

import {
    Gantt,
    Selection as SelectionGantt,
    VirtualScroll as VirtualScrollGantt,
    Filter as FilterGantt,
    Sort as SortGantt,
    ColumnMenu as ColumnMenuGantt,
    Edit as EditGantt,
    UndoRedo as UndoRedoGantt,
    Toolbar as ToolbarGantt,
    Reorder as ReorderGantt,
} from "@syncfusion/ej2-gantt";
import moment from "moment";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { Query } from "@syncfusion/ej2-data";
//   import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-gantt/styles/material.css";

Gantt.Inject(
    Gantt,
    SelectionGantt,
    VirtualScrollGantt,
    FilterGantt,
    SortGantt,
    ColumnMenuGantt,
    EditGantt,
    UndoRedoGantt,
    ToolbarGantt,
    ReorderGantt
);

let lastUpdatedData;
class SyncFusionGantt {
    constructor({
        wrapper,
        element,
        data,
        initialDate,
        endDate,
        dropdownData,
        frm,
        theme,
    }) {
        registerLicense(
            "ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5RdkBiXX1bcXxRQ2lZ"
        );

        try {
            if (!wrapper) {
                throw new Error("Wrapper is required to initialize SyncFusionGantt.");
            }

            this.$wrapper = $(wrapper);
            this.element = element || "#Gantt";
            this.theme = theme || "";
            let isUpdating = false;

            $("<link/>", {
                rel: "stylesheet",
                type: "text/css",
                href: `https://cdn.syncfusion.com/ej2/27.2.5/${this.theme}`, // Altere o link conforme necessário
            }).appendTo("head");

            // Adiciona a classe do tema no <body>
            $("body").addClass(this.theme);

            let self = this;
            const GanttData = Array.isArray(data)
                ? data.map((task) => {
                    return {
                        TaskID: task.TaskID,
                        TaskName: task.TaskName,
                        TaskDescription: task.TaskDescription,
                        Quantidade: task.Quantidade,
                        Duration: task.Duration,
                        Progress: task.Progress,
                        HorasPrevistas: task.HorasPrevistas,
                        HorasRealizadas: task.HorasRealizadas,
                        Estagio: task.Estagio,
                        Desenho: task.Desenho,
                        Posicao: task.Posicao,
                        Revisao: task.Revisao,
                        StartDate: new Date(task.StartDate),
                        EndDate: new Date(task.EndDate),
                        Dependencia: task.Predecessor
                            ? task.Predecessor.match(/\d+/g).join(",")
                            : "",
                        Predecessor: task.Predecessor,
                    };
                })
                : {
                    TaskID: 1,
                    TaskName: "OPx",
                    TaskDescription: "OPx",
                };

            L10n.load({
                pt: {
                    grid: {
                        EmptyRecord: "Nenhum registro para exibir",
                    },
                    gantt: {
                        id: "ID",
                        name: "Nome",
                        startDate: "Data de início",
                        endDate: "Data de término",
                        duration: "Duração",
                        progress: "Progresso",
                        dependency: "Dependência",
                        notes: "Notas",
                        "No records to display": "Nenhum registro para exibir",
                        baselineStartDate: "Data de início base",
                        baselineEndDate: "Data de término base",
                        taskMode: "Modo da tarefa",
                        changeScheduleMode: "Alterar modo de agendamento",
                        subTasksStartDate: "Data de início das subtarefas",
                        subTasksEndDate: "Data de término das subtarefas",
                        scheduleStartDate: "Data de início do agendamento",
                        scheduleEndDate: "Data de término do agendamento",
                        auto: "Automático",
                        manual: "Manual",
                        type: "Tipo",
                        offset: "Deslocamento",
                        resourceName: "Recursos",
                        resourceID: "ID do recurso",
                        day: "Dia",
                        hour: "Hora",
                        minute: "Minuto",
                        days: "Dias",
                        hours: "Horas",
                        minutes: "Minutos",
                        generalTab: "Geral",
                        customTab: "Colunas personalizadas",
                        writeNotes: "Escrever notas",
                        addDialogTitle: "Nova tarefa",
                        editDialogTitle: "Informações da tarefa",
                        saveButton: "Salvar",
                        add: "Adicionar",
                        edit: "Editar",
                        update: "Atualizar",
                        delete: "Excluir",
                        cancel: "Cancelar",
                        search: "Buscar",
                        task: "Tarefa",
                        tasks: "Tarefas",
                        zoomIn: "Aumentar zoom",
                        zoomOut: "Reduzir zoom",
                        zoomToFit: "Ajustar ao tamanho",
                        excelExport: "Exportar para Excel",
                        csvExport: "Exportar para CSV",
                        expandAll: "Expandir tudo",
                        collapseAll: "Recolher tudo",
                        nextTimeSpan: "Próximo período",
                        prevTimeSpan: "Período anterior",
                        okText: "OK",
                        confirmDelete: "Tem certeza de que deseja excluir este registro?",
                        from: "De",
                        to: "Para",
                        taskLink: "Vínculo da tarefa",
                        lag: "Atraso",
                        start: "Início",
                        finish: "Término",
                        enterValue: "Insira o valor",
                        taskBeforePredecessor_FS:
                            "Você moveu '{0}' para iniciar antes de '{1}' terminar, e ambas as tarefas estão vinculadas. Como resultado, os vínculos não podem ser mantidos. Escolha uma ação abaixo.",
                        taskAfterPredecessor_FS:
                            "Você moveu '{0}' para depois de '{1}', e ambas as tarefas estão vinculadas. Como resultado, os vínculos não podem ser mantidos. Escolha uma ação abaixo.",
                        taskBeforePredecessor_SS:
                            "Você moveu '{0}' para iniciar antes de '{1}' começar, e ambas as tarefas estão vinculadas. Como resultado, os vínculos não podem ser mantidos. Escolha uma ação abaixo.",
                        taskAfterPredecessor_SS:
                            "Você moveu '{0}' para iniciar após '{1}' começar, e ambas as tarefas estão vinculadas. Como resultado, os vínculos não podem ser mantidos. Escolha uma ação abaixo.",
                        taskBeforePredecessor_FF:
                            "Você moveu '{0}' para terminar antes de '{1}', e ambas as tarefas estão vinculadas. Como resultado, os vínculos não podem ser mantidos. Escolha uma ação abaixo.",
                        taskAfterPredecessor_FF:
                            "Você moveu '{0}' para terminar após '{1}', e ambas as tarefas estão vinculadas. Como resultado, os vínculos não podem ser mantidos. Escolha uma ação abaixo.",
                        taskBeforePredecessor_SF:
                            "Você moveu '{0}' para antes de '{1}' iniciar, e ambas as tarefas estão vinculadas. Como resultado, os vínculos não podem ser mantidos. Escolha uma ação abaixo.",
                        taskAfterPredecessor_SF:
                            "Você moveu '{0}' para terminar após '{1}' começar, e ambas as tarefas estão vinculadas. Como resultado, os vínculos não podem ser mantidos. Escolha uma ação abaixo.",
                        taskInformation: "Informações da tarefa",
                        deleteTask: "Excluir tarefa",
                        deleteDependency: "Excluir dependência",
                        convert: "Converter",
                        save: "Salvar",
                        above: "Acima",
                        below: "Abaixo",
                        child: "Subtarefa",
                        milestone: "Marco",
                        toTask: "Para tarefa",
                        toMilestone: "Para marco",
                        eventMarkers: "Marcadores de evento",
                        leftTaskLabel: "Etiqueta da tarefa à esquerda",
                        rightTaskLabel: "Etiqueta da tarefa à direita",
                        timelineCell: "Célula da linha do tempo",
                        confirmPredecessorDelete:
                            "Tem certeza de que deseja remover o vínculo de dependência?",
                        unit: "Unidade",
                        work: "Trabalho",
                        taskType: "Tipo de tarefa",
                        unassignedTask: "Tarefa não atribuída",
                        group: "Grupo",
                        indent: "Recuar",
                        outdent: "Diminuir recuo",
                        segments: "Segmentos",
                        splitTask: "Dividir tarefa",
                        mergeTask: "Mesclar tarefa",
                        left: "Esquerda",
                        right: "Direita",
                    },
                },
            });

            const currentYear = new Date().getFullYear();
            const ganttChart = new Gantt({
                dataSource: GanttData,
                theme: self.theme,
                locale: "pt",
                timelineSettings: {
                    showTooltip: true,
                    dateFormat: "dd",
                    topTier: {
                        unit: "Month",
                    },
                    bottomTier: {
                        unit: "Day",
                        format: "dd",
                    },
                    includeWeekend: true,
                },
                workWeek: [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ],
                height: "500px",
                dateFormat: "dd/MM/yyyy",
                highlightWeekends: false,
                showColumnMenu: true,
                actionBegin: async function (args) {
                    if (args.requestType === "beforeSave") {
                        // Faz uma cópia profunda da lista de tarefas para não modificar o original
                        const taskList = frm.doc.__onload.chart_data.data.map((task) => ({
                            ...task,
                        }));
                        const ids = taskList.map((task) => task.TaskID).map(String);

                        let dependencia = args.data.Dependencia;

                        if (dependencia) {
                            let dependencia_split = dependencia
                                .split(",")
                                .map((id) => id.trim())
                                .filter(
                                    (id) =>
                                        /^\d+$/.test(id) &&
                                        ids.includes(id) &&
                                        id !== args.data.TaskID.toString()
                                );

                            if (dependencia_split.length > 0) {
                                // Atualiza os campos Predecessor e Dependencia com o formato desejado
                                args.data.Predecessor = dependencia_split
                                    .map((id) => id + "FS")
                                    .join(",");
                                args.data.Dependencia = dependencia_split.join(",");
                            } else {
                                args.data.Predecessor = "";
                                args.data.Dependencia = "";
                                frappe.msgprint({
                                    message:
                                        "Dependência inválida. Por favor, insira um ID válido.",
                                    title: "Atenção",
                                    indicator: "red",
                                });
                                args.cancel = true; // Cancela a atualização
                                return;
                            }
                        } else {
                            args.data.Predecessor = "";
                            args.data.Dependencia = "";
                        }

                        // Atualiza a tarefa na lista clonada: faz uma substituição da tarefa que está sendo salva
                        const updatedTasks = ganttChart.dataSource.map((task) => {
                            if (task.TaskID.toString() === args.data.TaskID.toString()) {
                                // Mescla os dados antigos com os novos
                                return { ...task, ...args.data };
                            }
                            return task;
                        });
                        // Validação de dependência circular com a lista atualizada
                        if (hasCircularDependency(updatedTasks)) {
                            frappe.msgprint({
                                message:
                                    "Erro: Dependência circular detectada. Por favor, revise as dependências.",
                                title: "Atenção",
                                indicator: "red",
                            });
                            args.cancel = true;
                            return;
                        }

                        if (!isUpdating) {
                            isUpdating = true;
                            setTimeout(() => {
                                ganttChart.updateRecordByID(args.data);
                                isUpdating = false;
                            }, 1000);
                        }
                    }

                    // Função auxiliar para detectar ciclos (dependência circular)
                    function hasCircularDependency(tasks) {
                        // Monta o grafo: cada tarefa é uma chave e os vizinhos são as dependências (IDs separados por vírgula)
                        const graph = {};
                        tasks.forEach((task) => {
                            const taskId = task.TaskID.toString();
                            const deps = task.Dependencia
                                ? task.Dependencia.split(",")
                                    .map((dep) => dep.trim())
                                    .filter(Boolean)
                                : [];
                            graph[taskId] = deps;
                        });

                        const visited = {};
                        const recStack = {};

                        // Função recursiva (DFS) para detectar ciclos
                        function dfs(node) {
                            if (!visited[node]) {
                                visited[node] = true;
                                recStack[node] = true;

                                const neighbors = graph[node] || [];
                                for (const neighbor of neighbors) {
                                    if (!visited[neighbor] && dfs(neighbor)) {
                                        return true;
                                    } else if (recStack[neighbor]) {
                                        return true;
                                    }
                                }
                            }
                            recStack[node] = false;
                            return false;
                        }

                        // Verifica cada nó do grafo
                        for (const node in graph) {
                            if (dfs(node)) {
                                return true;
                            }
                        }
                        return false;
                    }
                },
                actionComplete: async function (args) {
                    if (args.action === "HorizontalScroll") {
                        return;
                    }
                    if (args.action === "CellEditing") {
                    }
                    if (args.requestType === "save") {
                        lastUpdatedData = args.data;

                        // passando a funcao para o backend
                        const res = frappe.call({
                            method: "nxlite.nx_producao.doctype.projeto.projeto.setGantt",
                            args: {
                                data: args,
                            },
                        });
                    }

                    if (args.requestType === "delete") {
                        const originalChartData = [...frm.doc.__onload.chart_data.data];
                        await frappe.confirm("Deseja excluir a Ordem de Produção?", async () => {
                            const op = args.data[0].TaskName;
                            const opexistis = await frappe.db.exists("Ordem de Producao", op);
                            if (opexistis) {
                                setTimeout(async () => {
                                    await frappe.db.delete_doc("Ordem de Producao", op);
                                    frm.doc.__onload.chart_data.data = frm.doc.__onload.chart_data.data.filter(item => item.TaskName !== op);
                                }, 1000);
                            }
                        }
                            , async () => {
                                frm.doc.__onload.chart_data.data = originalChartData;
                                ganttChart.dataSource = originalChartData;
                            });
                    }
                },
                allowFiltering: true,
                enableUndoRedo: true,
                allowSorting: true,
                allowResizing: true,
                allowReordering: true,
                allowKeyboard: true,
                allowSelection: true,
                selectionSettings: { mode: "Row", type: "Single" },
                filterSettings: { type: "Menu" },
                // showTodayMarker: true,
                enableVirtualization: false,
                taskFields: {
                    id: "TaskID",
                    name: "TaskName",
                    description: "TaskDescription",
                    quantidade: "Quantidade",
                    startDate: "StartDate",
                    estagio: "Estagio",
                    endDate: "EndDate",
                    duration: "Duration",
                    progress: "Progress",
                    dependency: "Predecessor",
                },
                editSettings: {
                    allowAdding: true,
                    allowEditing: true,
                    allowTaskbarEditing: true,
                    allowDeleting: true,
                },
                toolbar: ["Add", "Search"],
                toolbarClick: async (args) => {
                    console.log("ARGS", args);
                    args.cancel = true

                    if (args.item.text == "Adicionar") {
                        if (frm.is_new()) {
                            frappe.msgprint({
                                title: __('Atenção'),
                                indicator: 'orange',
                                message: __('Por favor, preencha os campos obrigatórios e salve o projeto antes de adicionar uma nova linha.')
                            });
                            return;
                        }
                        const data = frm.doc.__onload?.chart_data?.data;
                        let data_inicio_da_op_nova =
                            data && data.length ? data[data.length - 1] : null;

                        const data_inicio = data_inicio_da_op_nova
                            ? frappe.datetime.add_days(
                                data_inicio_da_op_nova.StartDate,
                                data_inicio_da_op_nova.Duration
                            )
                            : frappe.datetime.now_date();

                        const data_entrega = data_inicio_da_op_nova
                            ? frappe.datetime.add_days(data_inicio, data_inicio_da_op_nova.Durations)
                            : frappe.datetime.add_days(data_inicio, 1);


                        const { message: { dias_entrega } } = await frappe.db.get_value('Empresa', frm.doc.empresa, 'dias_entrega');
                        const dias__padrao_p_entrega = dias_entrega || 1;
                        const newOP = await frappe.db.insert({
                            doctype: "Ordem de Producao",
                            projeto: frm.doc.name,
                            empresa: frm.doc.empresa,
                            quantidade: 1,
                            entidade: frm.doc.entidade,
                            dt_inicio: frappe.datetime.now_date(),
                            dt_entrega: frappe.datetime.add_days(frappe.datetime.now_date(), dias__padrao_p_entrega),
                        });

                        const newTask = {
                            TaskID: ganttChart.dataSource.length + 1,
                            TaskName: newOP.name,
                            TaskDescription: newOP.descricao,
                            Quantidade: newOP.quantidade,
                            Duration: dias__padrao_p_entrega,

                            Estagio: newOP.estagio,
                            StartDate: newOP.dt_inicio,
                        }

                        frm.doc.__onload.chart_data.data.push(newTask)

                        ganttChart.addRecord(newTask, "Bottom")
                        ganttChart.refresh()
                    }
                },
                splitterSettings: {
                    position: "40%",
                    columnIndex: 2,
                },
                columns: [
                    { field: "TaskID", headerText: "ID", width: 50, allowEditing: true },
                    {
                        field: "TaskName",
                        headerText: "Nome",
                        width: 190,
                        allowEditing: false,
                        template: "#urlColTemplate",
                    },
                    {
                        field: "TaskDescription",
                        headerText: "Descrição",
                        width: 190,
                        allowEditing: true,
                    },
                    {
                        field: "Quantidade",
                        headerText: "Quantidade",
                        width: 140,
                        allowEditing: true,
                    },
                    {
                        field: "Desenho",
                        headerText: "Desenho",
                        width: 140,
                        allowEditing: false,
                    },
                    {
                        field: "Posicao",
                        headerText: "Posição",
                        width: 140,
                        allowEditing: false,
                    },
                    {
                        field: "Revisao",
                        headerText: "Revisão",
                        width: 140,
                        allowEditing: false,
                    },
                    {
                        field: "Estagio",
                        headerText: "Estagio",
                        width: 190,
                        allowEditing: true,
                        editType: "dropdownedit",
                        edit: {
                            params: {
                                query: new Query(),
                                dataSource: dropdownData,
                                fields: { value: "", text: "text" },
                                allowFiltering: true,
                            },
                        },
                    },
                    {
                        field: "StartDate",
                        headerText: "Início",
                        editType: "datepickeredit",
                        format: "dd/MM/yyyy",
                        edit: { params: { format: "dd/MM/yyyy" } }, // tip: Caso alguem tenha problema com a data, no editar campo ficar diferente da visualizacao precisa ajustar com essa linha
                        type: "date",
                        allowEditing: true,
                    },
                    {
                        field: "EndDate",
                        headerText: "Data de Entrega",
                        editType: "datepickeredit",
                        width: 190,
                        format: "dd/MM/yyyy",
                        edit: { params: { format: "dd/MM/yyyy" } }, // tip: Caso alguem tenha problema com a data, no editar campo ficar diferente da visualizacao precisa ajustar com essa linha
                        type: "date",
                        allowEditing: true,
                    },
                    {
                        field: "Dependencia",
                        headerText: "Dependência",
                        allowEditing: true,
                    },


                    { field: "Duration", headerText: "Duração", width: 120 },
                    {
                        field: "Progress",
                        headerText: "Progresso(%)",
                        width: 150,
                        allowEditing: false,
                    },
                    {
                        field: "Predecessor",
                        headerText: "Dependência",
                        allowEditing: true,
                        visible: false,
                    },
                    {
                        field: "HorasPrevistas",
                        headerText: "Horas Previstas",
                        width: 190,
                        allowEditing: false,
                    },
                    {
                        field: "HorasRealizadas",
                        headerText: "Horas Realizadas",
                        width: 190,
                        allowEditing: true,
                    },
                ],

                treeColumnIndex: 1,
                labelSettings: {
                    leftLabel: "TaskName",
                    rightLabel: "TaskDescription",
                },
                projectStartDate: initialDate || frappe.datetime.now_date(),
                projectEndDate: endDate || frappe.datetime.add_months(frappe.datetime.now_date(), 1),
            });
            this.$wrapper[0].innerHTML = "";
            ganttChart.appendTo(this.$wrapper[0]);
            loadCldr(n1, n2, n3, n4, s, s2);
            //
            setCulture("pt");
        } catch (e) {
            console.error("Error initializing SyncFusionGantt:", e);
        }
    }
}

export default SyncFusionGantt;
