import {
  TreeGrid,
  Page as PageTreeGrid,
  Sort as SortTreeGrid,
  Filter as FilterTreeGrid,
  Edit as EditTreeGrid,
  Toolbar as ToolbarTreeGrid,
  VirtualScroll as VirtualScrollTreeGrid,
  InfiniteScroll as InfiniteScrollTreeGrid,
} from "@syncfusion/ej2-treegrid";
import { registerLicense } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-treegrid/styles/material.css";
import { setCulture, L10n, loadCldr } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-treegrid/styles/material.css";
import "@syncfusion/ej2-treegrid/styles/material-dark.css";
import "vuetify/styles";

import * as n1 from "@syncfusion/ej2-cldr-data/main/pt/currencies.json";
import * as n2 from "@syncfusion/ej2-cldr-data/main/pt/timeZoneNames.json";
import * as n3 from "@syncfusion/ej2-cldr-data/main/pt/numbers.json";
import * as n4 from "@syncfusion/ej2-cldr-data/main/pt/ca-gregorian.json";
import * as s from "@syncfusion/ej2-cldr-data/supplemental/currencyData.json";
import * as s2 from "@syncfusion/ej2-cldr-data/supplemental/numberingSystems.json";
import { showEmptyGrid } from "@syncfusion/ej2-grids";

TreeGrid.Inject(
  PageTreeGrid,
  SortTreeGrid,
  FilterTreeGrid,
  EditTreeGrid,
  ToolbarTreeGrid,
  VirtualScrollTreeGrid,
  InfiniteScrollTreeGrid
);

class SyncFusionTreeGrid {
  constructor({ wrapper, element, data, theme, show_realizado = false }) {
    registerLicense(
      "ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5RdkBiXX1bcXxRQ2lZ"
    );
    try {
      if (!wrapper) {
        throw new Error(
          "Wrapper is required to initialize SyncFusionTreeGrid."
        );
      }

      this.$wrapper = $(wrapper);
      this.element = element || "#TreeGrid";
      this.theme = theme || "";

      $("<link/>", {
        rel: "stylesheet",
        type: "text/css",
        href: `https://cdn.syncfusion.com/ej2/27.2.3/${this.theme}`, // Altere o link conforme necessário
      }).appendTo("head");

      // Adiciona a classe do tema no <body>
      $("body").addClass(this.theme);

      const treeGridData = Array.isArray(data)
        ? data.map((item) => ({
          ElementEstrutura: item.ElementEstrutura,
          CustoEstrutura: item.CustoEstrutura.toLocaleString("pt-BR", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          // Condicionalmente adiciona o campo se showRealizado for true
          ...(show_realizado && {
            CustoEstruturaRealizado: item.CustoEstruturaRealizado.toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          }),
          Children: item.Children || [],
        }))
        : [];

      const columns = [{
        field: "ElementEstrutura",
        headerText: "Descrição",
        isPrimaryKey: true,
        width: 30,
        textAlign: "Left",
      },
      {
        field: "CustoEstrutura",
        headerText: "Custo Previsto",
        width: 30,
        textAlign: "Left",
      },]

      if (show_realizado) {
        columns.push({
          field: "CustoEstruturaRealizado",
          headerText: "Custo Realizado",
          width: 30,
          textAlign: "Left",
        });
      }

      //

      L10n.load({
        pt: {
          treegrid: {
            EmptyRecord: "Nenhum registro para exibir",
            Print: "Imprimir",
            FilterButton: "Filtrar",
            ClearButton: "Limpar",
            StartsWith: "Começa com",
            EndsWith: "Termina com",
            ExpandAll: "Expandir Tudo",
            CollapseAll: "Recolher Tudo",
            Search: "Procurar",
            Contains: "Contém",
            Equal: "Igual",
            NotEqual: "Diferente",
            LessThan: "Menor que",
            LessThanOrEqual: "Menor ou igual a",
            GreaterThan: "Maior que",
            GreaterThanOrEqual: "Maior ou igual a",
            EnterValue: "Insira o valor",
            FilterMenu: "Menu de filtro",
          },
          pager: {
            currentPageInfo: "{0} de {1} páginas",
            totalItemsInfo: "({0} itens)",
            firstPageTooltip: "Primeira página",
            lastPageTooltip: "Última página",
            nextPageTooltip: "Próxima página",
            previousPageTooltip: "Página anterior",
            nextPagerTooltip: "Próximo pager",
            previousPagerTooltip: "Pager anterior",
          },
          dropdowns: {
            noRecordsTemplate: "Nenhum registro encontrado",
          },
          datepicker: {
            placeholder: "Selecione uma data",
            today: "Hoje",
          },
        },
      });

      const treeGrid = new TreeGrid({
        dataSource: treeGridData,
        childMapping: "Children",
        parentIdMapping: "ParentId",
        height: "1000px",
        idMapping: "EstruturaId",
        InfiniteScrolling: true,
        pageSettings: {
          pageSize: 1000,
          pageCount: 5,
        },
        treeColumnIndex: 0,
        allowPaging: true,
        allowSorting: false,
        allowFiltering: false,
        allowEditing: false,
        editSettings: {
          allowAdding: false,
          allowEditing: false,
          allowDeleting: false,
          mode: "Row",
        },
        toolbar: ["Search", "ExpandAll", "CollapseAll"],
        columns: columns,
        childIndent: 80,

        queryCellInfo: function (args) {
          if (args.column.field === "ElementEstrutura") {
            let levelClass = args.cell.classList.value.match(/level(\d+)/);
            if (levelClass) {
              let level = parseInt(levelClass[1]);
              if (level < 2) {
              }
              args.cell.style.paddingLeft = `${level * 10}px`;
            }
          }
        },
      });

      treeGrid.appendTo(this.$wrapper[0]);

      loadCldr(n1, n2, n3, n4, s, s2);
      setCulture("pt"); // Defina a cultura para português
    } catch (e) {
      console.error("Error initializing SyncFusionTreeGrid:", e);
    }
  }
}

export default SyncFusionTreeGrid;
