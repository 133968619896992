import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_e_series = _resolveComponent("e-series")
  const _component_e_series_collection = _resolveComponent("e-series-collection")
  const _component_ejs_chart = _resolveComponent("ejs-chart")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createBlock(_component_v_row, { class: "d-flex justify-center align-center" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        style: {"width":"100%"},
        class: "d-flex flex-column px-0 justify-center align-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            class: "my-6 align-center ma-10 rounded-lg elevation-2",
            style: {"width":"100%","background-color":"transparent !important"}
          }, {
            default: _withCtx(() => [
              (!$data.display.xs)
                ? (_openBlock(), _createBlock(_component_v_card_title, {
                    key: 0,
                    class: "text-center d-flex justify-center mb-3",
                    style: {"font-weight":"bold","text-transform":"uppercase","font-size":"1.5rem","color":"#4CAF50","letter-spacing":"2px","text-shadow":"2px 2px 4px rgba(0, 0, 0, 0.2)","background-color":"transparent !important"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" PAGO X RECEBIDO ")
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              ($data.hasData)
                ? (_openBlock(), _createBlock(_component_ejs_chart, {
                    key: 1,
                    style: {"display":"block"},
                    theme: _ctx.theme,
                    align: "center",
                    id: "pagoXrecebido",
                    title: _ctx.title,
                    primaryXAxis: $data.primaryXAxis,
                    primaryYAxis: $data.primaryYAxis,
                    chartArea: $data.chartArea,
                    width: $data.width,
                    tooltip: $data.tooltip,
                    load: _ctx.load,
                    legendSettings: $data.legend
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_e_series_collection, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_e_series, {
                            dataSource: $data.seriesData,
                            type: "Column",
                            xName: "mes",
                            yName: "pagos",
                            name: "Valores a pagar",
                            width: "2",
                            marker: $data.marker,
                            tooltipMappingName: "MappingName",
                            columnSpacing: "0.1"
                          }, null, 8 /* PROPS */, ["dataSource", "marker"]),
                          _createVNode(_component_e_series, {
                            dataSource: $data.seriesData,
                            type: "Column",
                            xName: "mes",
                            yName: "recebidos",
                            name: "Valores a receber",
                            width: "2",
                            marker: $data.marker,
                            tooltipMappingName: "MappingName",
                            columnSpacing: "0.1"
                          }, null, 8 /* PROPS */, ["dataSource", "marker"]),
                          _createVNode(_component_e_series, {
                            dataSource: $data.seriesData,
                            type: "Column",
                            xName: "mes",
                            yName: "diferenca",
                            name: "Diferença do mês",
                            width: "2",
                            marker: $data.marker,
                            tooltipMappingName: "MappingName",
                            columnSpacing: "0.1"
                          }, null, 8 /* PROPS */, ["dataSource", "marker"]),
                          _createVNode(_component_e_series, {
                            dataSource: $data.seriesData,
                            type: "Spline",
                            xName: "mes",
                            yName: "saldo_acumulado",
                            name: "Valor Acumulado",
                            width: "3",
                            animation: $data.animation1
                          }, null, 8 /* PROPS */, ["dataSource", "animation"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["theme", "title", "primaryXAxis", "primaryYAxis", "chartArea", "width", "tooltip", "load", "legendSettings"]))
                : (_openBlock(), _createBlock(_component_v_row, {
                    key: 2,
                    class: "d-flex"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        class: "justify-center align-center",
                        cols: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card, {
                            class: "mx-2 mt-5 d-flex",
                            height: "20vh",
                            style: {"width":"100%","background-color":"transparent !important"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_text, {
                                class: "d-flex justify-center align-center",
                                style: {"font-weight":"bold","text-transform":"uppercase","font-size":"1.5rem","color":"orange","letter-spacing":"2px","text-shadow":"2px 2px 4px rgba(0, 0, 0, 0.2)"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Nenhum dado encontrado no período.")
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}