<template>
    <v-row class="d-flex justify-center align-center">
        <v-col cols="12" style="width: 100%;" class="d-flex flex-column px-0 justify-center align-center">
            <v-card class="my-6 align-center ma-10 rounded-lg elevation-2"
                style="width: 100%; background-color: transparent !important;">
                <v-card-title v-if="!display.xs" class="text-center d-flex justify-center mb-3" style="
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    color: #4CAF50;
                    letter-spacing: 2px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                    background-color: transparent !important;
                    ">
                     FORNECEDORES MAIS REPRESENTATIVOS
                </v-card-title>

                <v-card-title v-else class="text-center d-flex justify-center mb-3" style="
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    color: #4CAF50;
                    letter-spacing: 2px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                    background-color: transparent !important;
                    ">
                     FORNECEDORES MAIS <br> REPRESENTATIVOS
                </v-card-title>

                <v-row class="d-flex">
                    <v-col class="mb-6 ml-3" cols="11" md="6" lg="4">
                        <ejs-daterangepicker :placeholder="waterMarkText" @change="onDateRangeChange">
                            <e-presets>
                                <e-preset label="Mês Atual" :start='currentMonthStartDate'
                                    :end='currentMonthEndDate'></e-preset>
                                <e-preset label="Mês Anterior" :start='oneMonthStartDate'
                                    :end='oneMonthEndDate'></e-preset>
                                <e-preset label="3 Meses Anteriores" :start='threeMonthsStartDate'
                                    :end='threeMonthsEndDate'></e-preset>
                                <e-preset label="6 Meses Anteriores" :start='sixMonthsStartDate'
                                    :end='sixMonthsEndDate'></e-preset>
                                <e-preset label="Último Ano" :start='twelveMonthsStartDate'
                                    :end='twelveMonthsEndDate'></e-preset>
                            </e-presets>
                        </ejs-daterangepicker>
                    </v-col>
                </v-row>

                <div v-if="hasData">
                    <v-card-title class="d-flex justify-center">
                        Valor Total: <br> R$ {{ valorTotal.toFixed(2) }}
                    </v-card-title>

                    <ejs-accumulationchart style='display:block' :theme='theme' align='center'
                        id='analiseClienteRepresentativo' :chartArea='chartArea' :legendSettings='legendSettings'
                        :pointRender='pointRender' :centerLabel='centerLabel' :enableBorderOnMouseMove='false'>
                        <e-accumulation-series-collection>
                            <e-accumulation-series :dataSource='seriesData' :border='border' xName='nome'
                                :radius='radius' yName='valor' :startAngle='startAngle' :dataLabel='dataLabel'
                                innerRadius='65%' name='clientes'> </e-accumulation-series>

                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
                
                <v-row v-else class="d-flex">
                    <v-col class="justify-center align-center" cols="12">
                        <v-card class="mx-2 mt-5 d-flex" height="20vh"
                            style="width: 100%; background-color: transparent !important;">
                            <v-card-text class="d-flex justify-center align-center" style="
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    color: orange;
                    letter-spacing: 2px;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                    ">Nenhum dado encontrado para o período selecionado.</v-card-text>
                        </v-card>
                    </v-col>
                </v-row>


            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { DateRangePickerComponent, PresetDirective, PresetsDirective } from '@syncfusion/ej2-vue-calendars';
import { Browser } from '@syncfusion/ej2-base';
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, PieSeries, AccumulationLegend, AccumulationDataLabel, AccumulationTooltip } from "@syncfusion/ej2-vue-charts";
import { toDisplayString } from 'vue';
import { useDisplay } from 'vuetify';
export default {
    components: {
        'ejs-daterangepicker': DateRangePickerComponent,
        'e-presets': PresetsDirective,
        'e-preset': PresetDirective,
        'ejs-accumulationchart': AccumulationChartComponent,
        'e-accumulation-series-collection': AccumulationSeriesCollectionDirective,
        'e-accumulation-series': AccumulationSeriesDirective
    },
    name: "analiseGraficoFornecedoresMaisRepresentativos",
    data() {
        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        return {
            display: useDisplay(),
            valorTotal: 0,
            hasData: true,
            waterMarkText: 'Selecione a data',
            currentMonthStartDate: new Date(year, month, 1),
            currentMonthEndDate: new Date(year, month + 1, 0),
            oneMonthStartDate: new Date(year, month - 1, 1),
            oneMonthEndDate: new Date(year, month, 0),
            threeMonthsStartDate: new Date(year, month - 3, 1),
            threeMonthsEndDate: new Date(year, month, 0),
            sixMonthsStartDate: new Date(year, month - 6, 1),
            sixMonthsEndDate: new Date(year, month, 0),
            twelveMonthsStartDate: new Date(year - 1, month, 1),
            twelveMonthsEndDate: new Date(year, month, 0),
            seriesData: [],
            chartArea: {
                border: {
                    width: 0
                }
            },
            dataLabel: {
                visible: true,
                name: 'percentual',
                format: '%{value}',
                position: 'Outside',
                font: {
                    fontWeight: '600',
                    color: 'var(--text-light)'
                },
                connectorStyle: {
                    length: '20px',
                    type: 'Curve'
                }
            },
            centerLabel: {
                text: 'Empresas',
                hoverTextFormat: '${point.x} <br> Valor Total: R$${point.y}',
                textStyle: {
                    fontWeight: '600',
                    size: Browser.isDevice ? '8px' : '15px',
                    color: 'var(--text-light)'
                },
            },
            tooltip: {
                enable: true,
                format: "",
                header: "",
            },
            radius: Browser.isDevice ? '40%' : '70%',
            border: { width: 1 },
            legendSettings: {
                visible: true,
                textStyle: {
                    color: 'var(--text-light)'
                }
            },
            startAngle: Browser.isDevice ? '30' : '62',
        };
    },
    provide: {
        accumulationchart: [AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel]
    },
    mounted() {
        this.fetchInicial()

        frappe.realtime.on("Titulo", async (data) => {
            console.log(data)
            setTimeout(async () => {
                await this.fetchInicial()
            }, 1000);
        })
    },
    methods: {
        async onDateRangeChange(e) {
            const startDate = e.startDate;
            const endDate = e.endDate;

            const start = startDate || this.oneMonthAgoStartDate;
            const end = endDate || this.oneMonthAgoEndDate;
            await this.fetchInicial(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'));
        },
        async fetchInicial(startDate, endDate) {
            const { message } = await frappe.call(
                "nxlite.nx_financeiro.page.meu_financeiro.controller.chart_analise_grafico_fornecedores_mais_representativos",
                {
                    data_inicial: startDate,
                    data_final: endDate
                }
            )
            this.seriesData = message.Fornecedores
            this.hasData = this.seriesData.length > 0 ? true : false
            this.valorTotal = message.valor_total
        }
    }
}
</script>