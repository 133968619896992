import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_cardContasAReceberAtrasados = _resolveComponent("cardContasAReceberAtrasados")
  const _component_cardContasAPagarAtrasados = _resolveComponent("cardContasAPagarAtrasados")
  const _component_cardContasAPagarHoje = _resolveComponent("cardContasAPagarHoje")
  const _component_cardContasAReceberrHoje = _resolveComponent("cardContasAReceberrHoje")
  const _component_v_row = _resolveComponent("v-row")
  const _component_chartAnaliseResultadoOperacional = _resolveComponent("chartAnaliseResultadoOperacional")
  const _component_analiseGraficoClientesMaisRepresentativos = _resolveComponent("analiseGraficoClientesMaisRepresentativos")
  const _component_analiseGraficoFornecedoresMaisRepresentativos = _resolveComponent("analiseGraficoFornecedoresMaisRepresentativos")
  const _component_chartAnaliseGraficoMaioresGastosPorPlanoDeContas = _resolveComponent("chartAnaliseGraficoMaioresGastosPorPlanoDeContas")
  const _component_chartFluxoDeCaixa = _resolveComponent("chartFluxoDeCaixa")
  const _component_chartPagoXRecebido = _resolveComponent("chartPagoXRecebido")
  const _component_pivotFinanceiro = _resolveComponent("pivotFinanceiro")
  const _component_v_main = _resolveComponent("v-main")
  const _component_v_app = _resolveComponent("v-app")

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createCommentVNode(" cards  "),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_cardContasAReceberAtrasados),
              _createVNode(_component_cardContasAPagarAtrasados),
              _createVNode(_component_cardContasAPagarHoje),
              _createVNode(_component_cardContasAReceberrHoje)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_chartAnaliseResultadoOperacional),
          _createVNode(_component_analiseGraficoClientesMaisRepresentativos),
          _createVNode(_component_analiseGraficoFornecedoresMaisRepresentativos),
          _createVNode(_component_chartAnaliseGraficoMaioresGastosPorPlanoDeContas),
          _createVNode(_component_chartFluxoDeCaixa),
          _createVNode(_component_chartPagoXRecebido),
          _createVNode(_component_pivotFinanceiro)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}